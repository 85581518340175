import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from 'antd';
import Logo from "assets/svg/logo-color.svg";
import axios from 'axios';
import { useTranslation } from 'contexts/TranslationContext';
import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import env from "shared/constants/env";
import { LANGUAGES } from "../contexts/TranslationContext";

const LanguageDropdown = () => {
  const { language, setLanguage } = useTranslation();

  const languages = useMemo(
    () =>
      Object.keys(LANGUAGES).map((key) => ({
        key: LANGUAGES[key]?.code,
        label: (
          <span onClick={() => setLanguage(LANGUAGES[key])}>
            {LANGUAGES[key]?.name}
          </span>
        ),
        disabled: language?.code === LANGUAGES[key]?.code,
      })),
    [language, setLanguage]
  );

  return (
    <Dropdown menu={{ items: languages }}>
      <Space>
        <span className="font-bold">{language?.shortname}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

const AcceptTerms = () => {
  const { translate } = useTranslation();
  const location = useLocation();
  const [buttonText, setButtonText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); // Extract the token from the query parameters

  const handleAccept = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${env.PORTAL_API_ENDPOINT}auth/accept-terms-and-conditions?token=${token}`);
      if (data.success) {
        setButtonText(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      console.log(data, "data");
    } catch (err) {
      console.error("Failed to fetch terms:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="h-screen">
      <header className="rounded border border-gray-200 min-h-16 mb-4 px-4 py-8 bg-white flex items-center">
        <div>
          <Link to="/">
            <img alt="App logo" src={Logo} className="cursor-pointer" />
          </Link>
        </div>
        <div className="flex-grow"></div>
        <div className="flex items-center gap-8">
          <LanguageDropdown />
        </div>
      </header>
      <div className="flex flex-col items-center px-6">
        <div className="w-full mb-6">
          <div className="uppercase text-primary text-[28px] text-lg font-[700] mb-4"> {/* Updated title */}
            {translate("SIGN_UP.TERMS_MODEL.TITLE", "Terms and Conditions")}
          </div>
          <p className="text-sm">
            {translate("SIGN_UP.TERMS_MODEL.CONTENT")}
          </p>

          <div className="flex justify-end mt-4"> {/* Added flex container for button alignment */}
            <Button
              onClick={handleAccept}
              type="primary"
              disabled={buttonText}
              loading={isLoading}
              size="large"
              style={{
                backgroundColor: buttonText ? '#d1d5db' : '#F59E0B',
                borderColor: buttonText ? '#d1d5db' : '#F59E0B',
                color: buttonText ? '#6b7280' : '#fff',
              }}
              className="w-full max-w-md"
            >
              {buttonText ? translate("SIGN_UP.TERMS_MODEL.ACCEPTED") : translate("SIGN_UP.TERMS_MODEL.ACTION")}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AcceptTerms;
