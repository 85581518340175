import {useState} from "react";
import {useTranslation} from "../../contexts/TranslationContext";
import {Modal} from "antd";
import {Button} from "../../shared/components/Button";
import "./PrivacyModal.css"

 const PrivacyModal = () => {
    const [open, setOpen] = useState(false);
    const { translate } = useTranslation();

    return (
        <>
      <span
          className="text-primary underline cursor-pointer"
          style={{ fontSize: "12px" }}
          onClick={() => setOpen(true)}
      >
        {translate("SIGN_UP.PRIVACY.TITLE")}
      </span>
            <Modal
                className={'privacyModal'}
                closable={false}
                width={1000}
                open={open}
                onCancel={() => setOpen(false)}
                footer={
                    <>
                        <Button onClick={() => setOpen(false)} className="px-8">
                            {translate("SIGN_UP.DONE")}
                        </Button>
                    </>
                }
                title={
                    <div className="uppercase text-primary text-lg font-bold mb-8">
                        {translate("privacy_note.title_1")}
                    </div>
                }
            >
                <section>
                    <p className="headText">{translate('privacy_note.title')}</p>
                    <p>{translate('privacy_note.content')}</p>
                </section>

                <section>
                    <p className="headText">{translate('personal_data_collection_processing_disclosures.title')}</p>
                    <div
                        className="subHead">{translate('personal_data_collection_processing_disclosures.collection.title')}</div>
                    <ul>
                        <li>{translate('personal_data_collection_processing_disclosures.collection.content')}</li>
                    </ul>

                    <div
                        className="subHead">{translate('personal_data_collection_processing_disclosures.types.title')}</div>
                    <ul>
                        <li>{translate('personal_data_collection_processing_disclosures.types.content_1')}</li>
                        <li>{translate('personal_data_collection_processing_disclosures.types.content_2')}</li>
                        <li>{translate('personal_data_collection_processing_disclosures.types.content_3')}</li>
                        <li>{translate('personal_data_collection_processing_disclosures.types.content_4')}</li>
                        <li>{translate('personal_data_collection_processing_disclosures.types.content_5')}</li>
                        <li>{translate('personal_data_collection_processing_disclosures.types.content_6')}</li>
                    </ul>
                </section>

                <section>
                    <p className="headText">{translate('personal_information_collection.title')}</p>

                    <div className="subHead">{translate('personal_information_collection.what_we_collect.title')}</div>
                    <ul>
                        <li>{translate('personal_information_collection.what_we_collect.content_1')}</li>
                        <li>{translate('personal_information_collection.what_we_collect.content_2')}</li>
                        <li>{translate('personal_information_collection.what_we_collect.content_3')}</li>
                        <li>{translate('personal_information_collection.what_we_collect.content_4')}</li>
                        <li>{translate('personal_information_collection.what_we_collect.content_5')}</li>
                        <li>{translate('personal_information_collection.what_we_collect.content_6')}</li>
                        <li>{translate('personal_information_collection.what_we_collect.content_7')}</li>
                        <li>{translate('personal_information_collection.what_we_collect.content_8')}</li>
                    </ul>

                    <div className="subHead">{translate('personal_information_collection.from_whom.title')}</div>
                    <ul>
                        <li>{translate('personal_information_collection.from_whom.content_1')}</li>
                        <li>{translate('personal_information_collection.from_whom.content_2')}</li>
                    </ul>

                    <div className="subHead">{translate('personal_information_collection.purpose.title')}</div>
                    <ul>
                        <li>{translate('personal_information_collection.purpose.content_1')}</li>
                        <li>{translate('personal_information_collection.purpose.content_2')}</li>
                        <li>{translate('personal_information_collection.purpose.content_3')}</li>
                        <li>{translate('personal_information_collection.purpose.content_4')}</li>
                        <li>{translate('personal_information_collection.purpose.content_5')}</li>
                        <li>{translate('personal_information_collection.purpose.content_6')}</li>
                        <li>{translate('personal_information_collection.purpose.content_7')}</li>
                        <li>{translate('personal_information_collection.purpose.content_8')}</li>
                        <li>{translate('personal_information_collection.purpose.content_9')}</li>
                        <li>{translate('personal_information_collection.purpose.content_10')}</li>
                    </ul>
                </section>

                <section>
                    <p className="headText">{translate('data_retention.title')}</p>
                    <p>{translate('data_retention.content')}</p>
                </section>

                <section>
                    <p className="headText">{translate('data_sharing.title')}</p>
                    <p>{translate('data_sharing.content')}</p>
                </section>

                <section>
                    <p className="headText">{translate('minors_privacy.title')}</p>
                    <p>{translate('minors_privacy.content')}</p>
                </section>

                <section>
                    <p className="headText">{translate('changes_notice.title')}</p>
                    <p>{translate('changes_notice.content')}</p>
                </section>

                <section>
                    <p className="headText">{translate('data_subject_choices.title')}</p>
                    <p>{translate('data_subject_choices.title_content')}</p>
                    <ul>
                        <li>{translate('data_subject_choices.choice_1')}</li>
                        <li>{translate('data_subject_choices.choice_2')}</li>
                        <li>{translate('data_subject_choices.choice_3')}</li>
                        <li>{translate('data_subject_choices.choice_4')}</li>
                        <li>{translate('data_subject_choices.choice_5')}</li>
                        <li>{translate('data_subject_choices.choice_6')}</li>
                    </ul>
                    <p>{translate('data_subject_choices.content')}</p>
                </section>

                <section>
                    <p className="headText">{translate('data_access_correction.title')}</p>
                    <p>{translate('data_access_correction.content')}</p>
                </section>


            </Modal>
        </>
    );
}

export default PrivacyModal