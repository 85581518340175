import React, { useEffect, useState, useMemo } from "react";
import {LeftOutlined, CloseOutlined, RightOutlined} from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { Button } from "shared/components/Button";
import CancelModal from "./CancelModal";
import AcceptModal from "./AcceptModal";
import { API_URL } from "constants";
import { useNavigate, useParams } from "react-router-dom";
import routes from "shared/constants/routes";
import { toast } from "react-toastify";
import { useGetApplicationOfferQuery } from "services/auth";
import {useTranslation} from "../../../contexts/TranslationContext";

const Field = ({ label, value }) => (
  <>
    <div>{label}</div>
    <div className="font-bold text-md">{value || <>&mdash;</>}</div>
  </>
);

const Fields = ({ fields, values }) => {
  const {translate} = useTranslation();
  return (
    fields.length > 0 ? (
      <Row gutter={[16, 16]}>
        {fields.map((field, index) => (
          <Col xs={24} lg={field.span} key={field.key} className={`pb-2 ${index !== fields.length - 1 ? "border-r-2 border-b-2 border-gray-100" : ""}`}>
            <Field label={field.label} value={values[field.key]} />
          </Col>
        ))}
      </Row>
    ) :
      <div className="text-center">{translate('VIEW_OFFER.NO_DATA_FOUND')}</div>
  )
}


function Offer() {
  const { appId } = useParams();
  const navigate = useNavigate();
  const currency = "SAR";
  const {translate, language} = useTranslation();

  const { data, error, isLoading } = useGetApplicationOfferQuery(appId);

  useEffect(() => {
    if (!appId) {
      navigate(routes.HOME);
      toast.error("Application ID was not specified.");
      return;
    }
  }, [appId, navigate]);

  const offerData = useMemo(() => {
    //console.log("datadata", data);
    if (data) {
      return {
        FINANCE_AMOUNT: `${currency} ${data.FINANCE_AMOUNT}`,
        DOWN_PAYMENT: `${currency} ${data.DOWN_PAYMENT}`,
        BALLOON_PAYMENT: `${currency} ${data.BALLOON_PAYMENT}`,
        EMI: `${currency} ${data.EMI}`,
        AGREEMENT_TOTAL: `${currency} ${data.AGREEMENT_TOTAL}`,
        TENURE: `${data.TENURE}`,
        APR: `${data.EFFECTIVE_IRR}`
      };
    }
    return null;
  }, [data]);

  const applData = useMemo(() => {
    if (data) {
      return {
        KASTLE_APPL_ID: data.KASTLE_APPL_ID || "N/A",
        CUSTOMER: data.CUSTOMER || "N/A",
        PRODUCT: data.PRODUCT || "N/A",
      };
    }
    return null;
  }, [data]);

  const schema = useMemo(
    () => [
      { key: "FINANCE_AMOUNT", label: translate("VIEW_OFFER.FINANCE_AMOUNT"), span: 12 },
      { key: "DOWN_PAYMENT", label: translate("VIEW_OFFER.DOWN_PAYMENT"), span: 12 },
      { key: "BALLOON_PAYMENT", label: translate("VIEW_OFFER.BALLOON_PAYMENT"), span: 12 },
      { key: "EMI", label: translate("VIEW_OFFER.EMI"), span: 12 },
      { key: "AGREEMENT_TOTAL", label: translate("VIEW_OFFER.AGREEMENT_TOTAL"), span: 12 },
      { key: "TENURE", label: translate("VIEW_OFFER.TENURE"), span: 12 },
      { key: "APR", label: translate("VIEW_OFFER.APR"), span: 12 }
    ],
    [language.code]
  );

  if (error) {
    return <div>{translate("OFFER.ERRORLOADING")}</div>;
  }

  return (
    <div>
      <Card>
        <div className="flex items-center gap-4">
          <div onClick={() => navigate(`/app/${routes.HOME}`)} className="border border-gray-200 cursor-pointer w-8 h-8 rounded-lg flex items-center justify-center">
            { language.code === 'ar' ? <RightOutlined/> : <LeftOutlined />}
          </div>
          <h1 className="font-bold text-2xl"></h1>
        </div>

        {isLoading && <p>{translate('LOADING')}</p>}

        {offerData && applData && (
          <>
            <div className="my-5">
              <div className="flex mb-5 gap-3">
                {applData && (
                  <>
                    <div className="flex-1 p-3 border rounded-lg">
                      <span className="text-gray-500 mx-2">{translate("OFFER.CUSTOMERNAME")}</span>
                      <span className="text-black-500 font-bold">{applData.CUSTOMER}</span>
                    </div>
                    <div className="flex-1 p-3 border rounded-lg">
                      <span className="text-gray-500 mx-2">{translate("OFFER.KASTLENO")}</span>
                      <span className="text-black-500 font-bold">{applData.KASTLE_APPL_ID}</span>
                    </div>
                    <div className="flex-1 p-3 border rounded-lg">
                      <span className="text-gray-500 mx-2">{translate("OFFER.Product")}</span>
                      <span className="text-black-500 font-bold">{applData.PRODUCT}</span>
                    </div>
                  </>
                )}
              </div>
              <div className="border rounded-lg">
                <div className="p-5">
                  <Fields  fields={schema} values={offerData} />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-2">
              <CancelModal appId={appId} kastleId={applData.KASTLE_APPL_ID} />
              <AcceptModal appId={appId} kastleId={applData.KASTLE_APPL_ID} />
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

export default Offer;
