import { FormDrawer, buildInitialValues, buildValidationSchema } from "Components";
import { LoadingOutlined } from "@ant-design/icons";
import { withFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { PATCH_EMPLOYMENT_DETAILS, SET_ACTIVE_STEP_INDEX, SET_STEP_VALIDITY, useCreateRequestContext, SET_EDIT_APPL_LOAD_FLAGS } from "./CreateRequestContext";
import { employmentDetailsSchema } from "./schemas";
import { useTranslation } from "contexts/TranslationContext";
import { toast } from "react-toastify";
import moment from "moment-hijri";
import env from "shared/constants/env";

const EmploymentDetails = withFormik({
	mapPropsToValues: () => ({
		...buildInitialValues(
			employmentDetailsSchema({
				hideRealBenificiaryName: true,
				employmentTypes: [],
				residentStatuses: [],
				educationLevels: [],
				employmentSectors: [],
			})
		),
	}),
	validationSchema: buildValidationSchema(
		employmentDetailsSchema({
			hideRealBenificiaryName: true,
			employmentTypes: [],
			residentStatuses: [],
			educationLevels: [],
			employmentSectors: [],
		})
	),
	enableReinitialize: true,
	validateOnMount: true,
	// validateOnChange: true,
	// validateOnBlur: true,

})((props) => {
	const { state, dispatch } = useCreateRequestContext();
	const { translate } = useTranslation();

	const [employmentTypes, setEmploymentTypes] = useState([]);
	const [residentStatuses, setResidentStatuses] = useState([]);
	const [educationLevels, setEducationLevels] = useState([]);
	const [employmentSectors, setEmploymentSectors] = useState([]);
	const [initialDataLoading, setInitialDataLoading] = useState(true)


	const initializeData = async () => {
		try {
			const employmentSectorsPromise = fetch(
				`${env.API_ENDPOINT}lookup/empl_sector?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setEmploymentSectors(
						data.payload.rows.map((row) => ({
							label: row.SECTOR,
							value: String(row.SECTORID),
							labelAr: String(row.SECTOR_AR),
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load employment sectors.");
				});

			const educationLevelsPromise = fetch(
				`${env.API_ENDPOINT}lookup/education_level?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setEducationLevels(
						data.payload.rows.map((row) => ({
							label: row.QUALIFICATION,
							value: String(row.QUALIFICATIONID),
							labelAr: String(row.QUALIFICATION_AR),
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load education levels.");
				});

			const residentStatusesPromise = fetch(
				`${env.API_ENDPOINT}lookup/residential_status?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setResidentStatuses(
						data.payload.rows.map((row) => ({
							label: row.RESIDENT_STATUS,
							value: String(row.RESIDENTID),
							labelAr: String(row.RESIDENT_STATUS_AR),
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load residential statuses.");
				});

			const employmentTypesPromise = fetch(
				`${env.API_ENDPOINT}lookup/employment-categories?status=A`
			)
				.then((res) => res.json())
				.then((data) =>
					setEmploymentTypes(
						data.payload.rows.map((row) => ({
							label: row.EMPCATGNAME,
							value: String(row.EMPCATGID),
							labelAr: String(row.EMPCATGNAME_AR),
						}))
					)
				)
				.catch(() => {
					toast.error("Failed to load employment categories.");
				});

			await Promise.all([
				employmentSectorsPromise,
				educationLevelsPromise,
				residentStatusesPromise,
				employmentTypesPromise,
			]);

			// Set initial form values after all data is loaded
			props.setValues({
				...state.employmentDetails,
			});
		} finally {
			setInitialDataLoading(false)
		}
	};


	useEffect(() => {
		dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 1 });
	}, [dispatch]);

	useEffect(() => {
		dispatch({
			type: SET_STEP_VALIDITY,
			payload: {
				stepIndex: 1,
				valid: props.isValid,
			},
		});
	}, [dispatch, props.isValid]);

	useEffect(() => {
		//initializing
		initializeData()
	}, [])

	useEffect(() => {
		console.log(props.values);
		dispatch({
			type: PATCH_EMPLOYMENT_DETAILS,
			payload: props.values,
		});
	}, [dispatch, props.values]);


	const schemaFields = useMemo(() => {
		let hideRealBenificiaryName = true
		if (props.values?.realBenificiary === 'Yes') { //enable realBenificiaryName if option is yes
			hideRealBenificiaryName = false
		}
		return employmentDetailsSchema({
			hideRealBenificiaryName,
			employmentSectors,
			employmentTypes,
			residentStatuses,
			educationLevels,
		});
	}, [employmentSectors, employmentTypes, residentStatuses, educationLevels, props.values?.realBenificiary]);

	if (initialDataLoading) {
		return (
			<div className='p-5 bg-white flex flex-col gap-4'>
				<div className='flex gap-4'>
					<LoadingOutlined />
					<span>{translate("CREATE_REQUEST.LOADING_EMPLOYMENT_DETAILS")}</span>
				</div>
			</div>
		);
	}

	return (
		<>
			<h2 className='font-bold text-xl mb-4'>{translate("CREATE_REQUEST.EMPLOYMENT_DETAILS")}</h2>

			<div className='mb-4'>
				<FormDrawer
					schema={{
						fields: schemaFields,
					}}
					values={props.values}
					onChange={props.handleChange}
				/>
			</div>
		</>
	);
});

export default EmploymentDetails;